.testimony {
  @apply border-green border-2 rounded-lg bg-white p-6 text-left;
  box-shadow: 4px 10px 8px #00000029;
}

.testimony svg {
  @apply fill-blue;
}

.title {
  @apply flex items-center leading-tight;
}

.title div {
  @apply ml-4 text-lg;
}

.body {
  @apply mt-4 text-gray;
}

.author {
  @apply text-blue font-display mt-8 text-xl;
}

.company {
  @apply text-gray-300 text-sm;
}
