.wrap {
  @apply bg-blue text-center rounded-xl max-w-fit mx-auto my-8;
}
.wrap h1 {
  @apply text-white;
}

.wrap h2 {
  @apply text-white;
}

.wrap h3 {
  @apply text-white;
}

.wrap a {
  @apply text-white underline;
}

.wrap p {
  @apply text-white;
}

.full {
  @apply w-full max-w-none px-4 mx-0;
}

.title {
  @apply text-3xl font-light;
}

.base {
  @apply py-4 px-12;
}

.xl {
  @apply py-8 px-12;
}
