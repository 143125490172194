.content {
  @apply text-center;
}
.content h1 {
  @apply max-w-2xl;
}

.content h2 {
  @apply mb-6;
}

.highlight {
  @apply text-xl md:text-2xl lg:text-3xl font-body;
}

.highlight br {
  @apply hidden md:block;
}

.highlight a {
  @apply text-white underline;
}

.heading {
  @apply text-lg md:text-xl lg:text-2xl font-light text-center font-body mb-8;
}

.heading br {
  @apply hidden md:block;
}

.service_cards {
  @apply grid sm:grid-cols-2 lg:grid-cols-4 gap-4 md:gap-6 lg:gap-8;
}

.office_cards {
  @apply grid sm:grid-cols-2 lg:grid-cols-6 gap-4 md:gap-6 lg:gap-8;
}

.office_cards > div {
  @apply sm:col-span-1 lg:col-span-2;
}
.office_cards > div:nth-child(7) {
  @apply lg:col-start-2;
}

.badge {
  @apply flex px-6 py-4 items-center text-left justify-center;
}

.badge div {
  @apply ml-2;
}

.badge h4 {
  @apply text-2xl font-light font-body md:text-3xl text-blue leading-none mb-1;
}

.badge h5 {
  @apply text-lg font-light font-body md:text-xl text-gray-300 leading-none;
}

.why h2 {
  @apply text-2xl font-body md:text-3xl font-light leading-none mb-4;
}

.why h3 {
  @apply text-lg font-body;
}

.testimonies {
  @apply lg:-mt-36 mb-12;
}

.testimonies > div {
  @apply flex gap-8 flex-col md:flex-row  justify-between relative mt-8 lg:mt-0 w-full p-0 lg:px-5;
}

.testimonies > div > div {
  @apply w-full;
}
