.banner {
  @apply mb-6 overflow-hidden;
  min-height: 195px;
  max-height: calc((100vw / 640) * 344);
}

@media screen(lg) {
  .banner {
    max-height: 632px;
  }
}

.banner > div {
  @apply max-w-screen-2xl p-0 border-b-blue border-b-[20px] max-h-80 sm:max-h-fit;
  box-shadow: 0 -3px 3px #00000034;
}

.banner img {
  @apply block w-full h-auto object-cover;
}
